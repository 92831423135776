import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useFormik } from 'formik';

import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as sessionApi from './../../api/sessionApi';

import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    width: 200,
    padding: 5,
    borderRadius: 5
  },
  actionSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  progressCircle: {
    color: theme.palette.primary.contrastText
  }
}));

const ActivateAccountForm = ({ activationToken }) => {

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['validation', 'common', 'snackbar']);

  const [isValidToken, setToken] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const schema = Yup.object({
    password: Yup.string().required(t('validation:passwordIsRequired'))
      .matches(/^(?=.*\d).{6,15}$/),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation:passwordsMustMatch'))
      .matches(/^(?=.*\d).{6,15}$/)
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values) => {
      sessionApi.activateAccount(values.password, activationToken)
        .then(() => {
          history.push('/');
          enqueueSnackbar(t('snackbar:accountActivatedSuccess'), { variant: 'success' });
        })
        .catch(() => {
          history.push('/');
          enqueueSnackbar(t('snackbar:accountActivatedError'), { variant: 'error' });
        });
    }
  });

  useEffect(() => {
    sessionApi.validateToken(activationToken)
      .then((isValidToken) => {
        if (!isValidToken) {
          history.push('/');
          return;
        };

        setToken(isValidToken);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasError = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  if (submitted) {
    return (
      <Typography>
        { t("newTranslations:accountActivated") }
      </Typography>
    );
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.fields}>
          <TextField
            error={hasError("password")}
            fullWidth
            label={t("common:passwordLabel")}
            name="password"
            onChange={formik.handleChange}
            type="password"
            value={formik.values.password}
            variant="outlined"/>

          <TextField
            error={hasError("confirmPassword")}
            fullWidth
            label={t("common:confirmPasswordLabel")}
            name="confirmPassword"
            onChange={formik.handleChange}
            type="password"
            value={formik.values.confirmPassword}
            variant="outlined" />

          <Typography variant="h5">
            { t("validation:weakPassword") }
          </Typography>
        </div>

        <div className={classes.actionSection}>
          <Button
            className={classes.submitButton}
            color="secondary"
            disabled={!schema.isValid}
            size="large"
            type="submit"
            variant="contained">
            { t("common:activateAccount") }
          </Button>
        </div>
      </form>
    </>
  );

}

export default ActivateAccountForm;
